import { Component, OnInit } from "@angular/core";
import { Project } from "src/app/models/project";
import { ActivatedRoute } from "@angular/router";
import { ProjectService } from "src/app/services/project.service";

@Component({
  selector: "app-email-summary",
  templateUrl: "./email-summary.component.html",
  styleUrls: ["./email-summary.component.css"],
})
export class EmailSummaryComponent implements OnInit {
  projectID: string;
  project: Project;

  email: string;

  constructor(private route: ActivatedRoute, private projectService: ProjectService) {}

  ngOnInit() {
    this.projectID = this.route.parent.snapshot.paramMap.get("projectID");

    this.projectService.getProject(this.projectID).subscribe((p) => {
      this.project = p;
    });
  }

  addEmail() {
    if (this.project.dailyEmailList) {
      this.project.dailyEmailList.push(this.email);
    } else {
      this.project.dailyEmailList = [this.email];
    }
    this.email = "";

    this.projectService.updateProject(this.project).subscribe((p) => {
      this.project = p;
    });
  }

  removeEmail(i: number) {
    this.project.dailyEmailList.splice(i, 1);

    this.projectService.updateProject(this.project).subscribe((p) => {
      this.project = p;
    });
  }
}
