<div *ngIf="project && project.organizationPlanName == 'SANDBOX'">
  <p>
    Monitoring is not available for SANDBOX plans. Please
    <a [routerLink]="['/org', project.organizationID, 'billing']">update plan</a>
    to use this feature.
  </p>
</div>

<div *ngIf="project && project.organizationPlanName != 'SANDBOX'">
  <h3>Summary Emails</h3>

  <div class="row">
    <div class="col-md-6">
      <p>Receive a daily summary email of the reports received that day.</p>

      <div class="form-group">
        <label>Add Email:</label>
        <input class="form-control" type="email" [(ngModel)]="email" placeholder="myemail@email.com" />
      </div>
      <button class="float-right btn btn-primary" (click)="addEmail()">Add Email</button>
    </div>

    <div class="col-md-6" *ngIf="project && project.dailyEmailList">
      <h5>Current Email Addresses</h5>

      <div *ngFor="let email of project.dailyEmailList; index as emailIndex">
        {{ email }}

        <button class="btn btn-danger" (click)="removeEmail(emailIndex)">Remove Email</button>
      </div>
    </div>
  </div>
</div>
