import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Organization } from "src/app/models/organization";
import { Project } from "src/app/models/project";
import { APIKey, ApikeyService } from "src/app/services/apikey.service";
import { OrganizationService } from "src/app/services/organization.service";
import { ProjectService } from "src/app/services/project.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-project-apikey",
  templateUrl: "./project-apikey.component.html",
  styleUrls: ["./project-apikey.component.css"],
})
export class ProjectApikeyComponent {
  projectID: string;
  organization: Organization;
  project: Project;
  isCorrectPlan: boolean;
  apiKeys: APIKey[] = [];

  newAPIKey: APIKey;

  errorStr = "";
  newAPIKeyError = "";

  constructor(
    private route: ActivatedRoute,
    private apiKeyService: ApikeyService,
    private projectService: ProjectService,
    private organizationService: OrganizationService
  ) {}

  ngOnInit() {
    this.projectID = this.route.parent.snapshot.paramMap.get("projectID");
    this.projectService.getProject(this.projectID).subscribe((project) => {
      this.project = project;

      this.organizationService.getOrganization(this.project.organizationID).subscribe((organization) => {
        this.isCorrectPlan = organization.planName.startsWith("BUSINESS") || organization.planName.startsWith("ENTERPRISE");
        this.organization = organization;
      });
    });

    this.newAPIKey = {
      projectID: this.projectID,
    } as APIKey;

    this.apiKeyService.getAPIKeysForProject(this.projectID).subscribe((apiKeys) => {
      this.apiKeys = apiKeys;
      if (!this.apiKeys) {
        this.apiKeys = [];
      }
    });
  }

  saveAPIKey() {
    this.newAPIKey.projectID = this.projectID;
    this.apiKeyService.newAPIKey(this.newAPIKey).subscribe(
      (key) => {
        this.apiKeys.push(key);
        this.newAPIKey = key;
      },
      (e) => {
        this.newAPIKeyError = e.error;
      }
    );
  }

  deleteAPIKey(key: APIKey) {
    this.apiKeyService.deleteAPIKey(key.id).subscribe(() => {
      this.apiKeys = this.apiKeys.filter((k) => k !== key);
    });
  }
}
